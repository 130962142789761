import React from 'react'
import { Helmet } from 'react-helmet'

import '../styles/reset.css'

import SiteHeader from '../components/SiteHeader'

import './styles.module.scss'

const Layout = (props) => {
  const { children } = props

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Black Honey | Narrabeen Cafe</title>
        <meta name="description" content="Black Honey is a cafe located in Narrabeen on Sydney's Northern Beaches" />

        <meta property="og:site_name" content="Black Honey" />
        <meta property="og:title" content="Black Honey & Brew - Cafe in Narrabeen" />
        <meta property="og:description" content="Black Honey is a cafe located in Narrabeen on Sydney's Northern Beaches." />
        <meta property="og:image" content="/og-image.png" />
      </Helmet>

      <SiteHeader />

      <div>{children}</div>
    </div>
  )
}

export default Layout
import React from 'react'
import classnames from 'classnames'

import * as styles from './styles.module.scss'

const ContentHolder = (props) => {
  const { children, className } = props

  return (
    <div className={classnames(styles.root, className)}>
      {children}
    </div>
  )
}

export default ContentHolder

// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-header-index-jsx": () => import("./../../../src/pages/Index/Header/index.jsx" /* webpackChunkName: "component---src-pages-index-header-index-jsx" */),
  "component---src-pages-index-hero-image-index-jsx": () => import("./../../../src/pages/Index/HeroImage/index.jsx" /* webpackChunkName: "component---src-pages-index-hero-image-index-jsx" */),
  "component---src-pages-index-image-grid-index-jsx": () => import("./../../../src/pages/Index/ImageGrid/index.jsx" /* webpackChunkName: "component---src-pages-index-image-grid-index-jsx" */),
  "component---src-pages-index-index-jsx": () => import("./../../../src/pages/Index/index.jsx" /* webpackChunkName: "component---src-pages-index-index-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-location-index-jsx": () => import("./../../../src/pages/Index/Location/index.jsx" /* webpackChunkName: "component---src-pages-index-location-index-jsx" */),
  "component---src-pages-menu-footer-index-jsx": () => import("./../../../src/pages/Menu/Footer/index.jsx" /* webpackChunkName: "component---src-pages-menu-footer-index-jsx" */),
  "component---src-pages-menu-index-jsx": () => import("./../../../src/pages/Menu/index.jsx" /* webpackChunkName: "component---src-pages-menu-index-jsx" */),
  "component---src-pages-menu-js": () => import("./../../../src/pages/menu.js" /* webpackChunkName: "component---src-pages-menu-js" */),
  "component---src-pages-menu-menu-group-index-jsx": () => import("./../../../src/pages/Menu/MenuGroup/index.jsx" /* webpackChunkName: "component---src-pages-menu-menu-group-index-jsx" */),
  "component---src-pages-menu-menu-group-menu-item-index-jsx": () => import("./../../../src/pages/Menu/MenuGroup/MenuItem/index.jsx" /* webpackChunkName: "component---src-pages-menu-menu-group-menu-item-index-jsx" */),
  "component---src-pages-menu-menu-header-index-jsx": () => import("./../../../src/pages/Menu/MenuHeader/index.jsx" /* webpackChunkName: "component---src-pages-menu-menu-header-index-jsx" */),
  "component---src-pages-menu-menu-navigation-index-jsx": () => import("./../../../src/pages/Menu/MenuNavigation/index.jsx" /* webpackChunkName: "component---src-pages-menu-menu-navigation-index-jsx" */)
}


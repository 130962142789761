import React, { useState } from 'react'
import { Link } from 'gatsby'

import ContentHolder from '../ContentHolder'

import Logo from 'images/logo.svg'
import MenuIcon from 'images/bars.svg'

import * as styles from './styles.module.scss'

const pages = [
  { key: 'home', path: '/', title: 'Home' },
  { key: 'menu', path: '/menu', title: 'Menu' },
]

const SiteHeader = () => {
  const [showMobileMenu, setShowMobileMenu] = useState(false)

  return (
    <>
      <div className={styles.root}>
        <ContentHolder className={styles.contentHolder}>
          <Link to="/">
            <Logo height={50} width={91} />
          </Link>

          <ul className={styles.navigation}>
            {pages.map((page) => (
              <Link activeClassName={styles.active} key={page.key} to={page.path}>
                {page.title}
              </Link>
            ))}
          </ul>

          <MenuIcon
            className={styles.toggleMenu}
            onClick={() => setShowMobileMenu(!showMobileMenu)}
            width={20}
            height={20}
          />
        </ContentHolder>
      </div>

      {showMobileMenu && (
        <div className={styles.mobileNavigation}>
          <ul className={styles.mobileNavigationLinks}>
            {pages.map((page) => (
              <Link
                activeClassName={styles.active}
                key={page.key}
                onClick={() => setShowMobileMenu(false)}
                to={page.path}
              >
                {page.title}
              </Link>
            ))}
          </ul>
        </div>
      )}
    </>
  )}

export default SiteHeader
